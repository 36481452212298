/** @jsx jsx */
import { jsx, Box, Container} from 'theme-ui';
import { rgba } from 'polished';
import SignupForm from 'components/signup-form';
import heroImg from 'assets/images/mou-svg.svg';

const Signup = (props) => {
  return (
    <section id="signup" sx={styles.section}>
      <Container>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.imageWrapper}>
            <Box as="figure" sx={styles.bannerImage}>
              <img
                src={heroImg}
                alt="illustration"
              />
            </Box>
          </Box>
          <Box sx={styles.bannerContent}>
            <SignupForm sx={styles.subscriptionForm} promocode={props.promocode} accountType={props.accountType} purpose={"pricing"} pricingPlan={props.pricingPlan}/>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default Signup;

const styles = {
  section: {
    pt: [14, null, null, null, null, 0],
    pb: [6, null, null, 7, 11, 0],
  },
  contentWrapper: {
    gap: ['50px 50px'],
    display: ['block', null, null, null, 'grid'],
    gridTemplateColumns: [null, null, null, null, '1fr 1fr', '0.95fr 1.05fr'],
    alignItems: 'center',
    minHeight: ['auto', null, null, null, null, null],
    //pt: [null, null, null, 8, 0, 9, null],
    paddingBottom: '40px',
    marginTop: '40px',
    '@media only screen and (min-width:1900px)': {
      pt: 10,
    },
    paddingTop: '0 !important'
  },
  bannerContent: {
    margin: [null, null, null, '0 auto', 0],
    maxWidth: [null, null, null, 600, 'none'],
    textAlign: [null, null, null, 'center', 'left'],
    h1: {
      fontWeight: 700,
      fontSize: [8, null, null, 10, 45, null, 12, 12],
      lineHeight: [1.26, null, null, 1.5, 1.2, 1.26],
      letterSpacing: [0, null, null, null, '-1.5px'],
      color: 'textSecondary',
      '@media screen and (min-width: 1441px) and (max-width:1600px)': {
        fontSize: 12,
        lineHeight: 1.5,
      },
    },
    p: {
      fontSize: [1, null, null, 2, 3],
      lineHeight: [1.5, null, null, 2, null, 2.33],
      color: 'textSecondary',
      maxWidth: [470],
      m: [null, null, null, '30px auto', 0],
      mt: [3, null, null, null, 0],
    },
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  subscriptionForm: {
    maxWidth: [null, null, null, 470, 'none'],
    m: [null, null, null, '5px auto', '5px 0 0'],
    mt: [1],
    input: {
      backgroundColor: '#FDFDFD',
      border: '1px solid #eee',
      fontFamily: 'body',
      fontSize: [1, null, null, null, 2],
      px: [5],
      boxShadow: '0px 16px 40px rgba(72, 59, 26, 0.08)',
      minHeight: [40, 50, null, null, null, 50],
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      '::placeholder': {
        color: rgba('#02073E', 0.4),
        opacity: 1 /* Firefox */,
      },
      mb: [3],
      width: [500, 500, 500, 500, 400, 500]
    },
    button: {
      fontSize: [0, 1, null, null, 2],
      minHeight: [40, 50, null, null, null, 50],
      marginLeft: '0px !important'
    },
  },
  bannerImage: {
    justifyContent: 'center',
    alignItems: 'center',
    mt: [9, null, null, null, 0],
    img: {
      maxWidth: 400,
      m: [null, null, null, '0 auto', 0],
    },
  },
};
