/** @jsx jsx */
import { jsx, Box, Container, Grid } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';
import Accordion from 'components/accordion/accordion';
import featureGif from 'assets/gifs/TABELA-GIF.gif';


const MoreText = () => {

  return (
    <section id="features">
      <Container>
        <Grid>
          <Box>
            <SectionHeading
              // emoji={emoji}
              sx={styles.heading}
              title={'Começar com a MOU é rápido e simples'}
              description={
                <span>
                  <strong>Você pode</strong> utilizar bases ou interpretações prontas disponibilizadas pela MOU ou compartilhadas por outros usuários como você. 
                  <strong> Sim</strong>, porque a MOU conecta você com outros usuários de modo inédito.<br/>
                  <strong>Não se preocupe</strong> se as medidas não atendem sua expectativa, você pode mudar todas elas em um clique, alterando uma medida, 
                  o tamanho base ou até mesmo a tabela de forma simples e rápida.<br/>
                  Você também pode <strong>compartilhar</strong> as modelagens com amigos, colegas e empresas de modo seguro, dentro da própria plataforma MOU.<br/>
                  Crie sua própria biblioteca de bases reutilizáveis, facilmente interpretáveis e com gradação instantânea.<br/> 
                  Seja <strong>incrivelmente produtivo</strong> no seu dia-a-dia, gaste menos tempo com tarefas repetitivas e dedique mais tempo à criatividade!<br/>
                </span>
              }
            />
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default MoreText;

const styles = {
  section: {
    pt: [6, null, null, 6, 8, 9],
    pb: [6, null, null, null, 7, 9, 11, null],
  },
  heading: {
    textAlign: ['left', null, null, 'left', 'left'],
    ml: [null, null, null, 'auto'],
    maxWidth: 'none !important',
    h2: {
      fontSize: [null, null, null, 10, 8, 30],
    },
    p: {
      fontSize: [null, null, null, 3],
      maxWidth: 'none'
    },
    strong:{
      fontWeight: 'bold',
      color: '#CC6666'
    }
  },
};
