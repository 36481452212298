/** @jsx jsx */
import { jsx, Box, Container, Grid } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';
import Accordion from 'components/accordion/accordion';
import featureGif from 'assets/gifs/TABELA-GIF.gif';

const data = [
  {
    title: 'Alma da modelagem',
    contents: (
      <div>
        A tabela de medidas é essencial para o desenvolvimento de qualquer modelagem, na MOU ela agiliza o trabalho, 
        fornece informações sobre as medidas utilizadas para construção de traçados, bases e modelos, 
        possibilita uma gradação precisa e pode ser exportada em formato PDF.

      </div>
    ),
  },
  {
    title: '39 medidas',
    contents: (
      <div>
        A MOU oferece uma tabela com 39 medidas personalizáveis, que atende a variados tipos de construção de modelagem plana, 
        de acordo com o método e necessidade do usuário.
      </div>
    ),
  },
  {
    title: `Flexibilidade`,
    contents: (
      <div>
        Dentro da plataforma MOU a tabela de medidas tem como característica poder ser editada mesmo com a modelagem já pronta, 
        uso ilimitado de uma mesma tabela em projetos distintos, há ainda possibilidade de compartilhamento de tabela de forma segura e particular      
      </div>
    ),
  },
];

const TableFeature = () => {
  const image = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "messenger.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <section id="features" sx={styles.section}>
      <Container>
        <Grid sx={styles.grid}>
          <Box>
            <SectionHeading
              // emoji={emoji}
              sx={styles.heading}
              title="Tabela de Medidas"
              description="A tabela de medidas integra a modelagem e a área de trabalho, facilita a busca das informações e a gradação."
            />
            <Box sx={styles.accordionGroup}>
              <Accordion items={data} />
            </Box>
          </Box>
          <Box as="figure" sx={styles.illustration}>
                {/* <GifPlayer 
                  gif={featureGif} 
                  autoplay={true}
                  still={thumbs}
                  // pauseRef={pause => this.pauseGif = pause}
                  /> */}
                  <img
                  // src={image.illustration.childImageSharp.fluid}
                  src={featureGif}
                  alt="features"
                  />
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default TableFeature;

const styles = {
  section: {
    pt: [null],
    pb: [6, null, null, null, 7, 9, 11, null],
  },
  grid: {
    alignItems: 'center',
    gridTemplateColumns: [
      '1fr',
      '1fr',
      '1fr',
      '1fr',
      '1fr 420px',
      '1fr 530px',
      '1fr 550px',
    ],
  },
  heading: {
    position:[null,null,'relative','relative'],
    left:[null,null,null,null,'520px','550px'],
    m:['0 auto','0 auto','0 auto','0 auto',null],
    textAlign: ['left', 'left', 'left', 'left', 'left'],
    // m: [null, null, null,60,60, 'auto'],
    mb:[null,null,null,null,5],
    width:['auto','auto','auto','auto',420,500],
    maxWidth: [null, null, null, 520, 660],
    h2: {
      fontSize: [null, null, null, 10, 8, 40],
      img: {
        maxWidth: [24, null, null, 30, 25, null, '100%'],
        top: ['4px', '8px', null, null, '4px', '8px'],
      },
    },
    p: {
      fontSize: [null, null, null, 2],
    },
  },
  illustration: {
    m:['0 auto','0 auto','0 auto','0 auto',null],
    mb: [6, null, null, 8, 0],
    ml:[null,null, null,'0 auto',null],
    position: ['relative','relative','relative','relative','absolute'],
    zIndex: -1,
    img: {
      opacity: 0.9,
      width: [250,300,300,400,500,500,500],
      height: [200,250,250,350,450,450,450],
      borderRadius:'15px 15px 15px 15px',
      boxShadow:"gray 0px 4px 12px",
    }
  },
  accordionGroup: {
    width:['auto','auto','auto','auto',420,500],
    position:[null,null,null,'relative'],
    left:[null,null,null,null,'520px','550px'],
    m:['0 auto','0 auto','0 auto','0 auto',null],
    maxWidth: [null, null, null, 600, 'none'],
  },
};
