/** @jsx jsx */
import { jsx, Box, Container, Grid } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';
import Accordion from 'components/accordion/accordion';
import featureGif from 'assets/gifs/TABELA-GIF.gif';


const Introduction = () => {

  return (
    <section id="features">
      <Container>
        <Grid>
          <Box>
            <SectionHeading
              // emoji={emoji}
              sx={styles.heading}
              title={'A modelagem do vestuário no século XXI precisa ser: conectada, simples e rápida!'}
              description={
                <span>
                  Seja bem-vindo a plataforma de modelagem de vestuário feita sob medida para <strong>você</strong>.
                  Apresentamos a MOU, um aplicativo <strong>100% online</strong> voltado para a modelagem de vestuário.
                  Desenvolvida para <strong>conectar</strong> a modelagem digital ao mundo da modelagem analógica, 
                  empregando as melhores práticas da ergonomia e do desenvolvimento, de forma <strong>simples, intuitiva e inteligente</strong>.<br/>
                  <strong>Migrar seus projetos</strong> do papel para o digital nunca foi tão fácil, ágil e eficiente, você vai se surpreender!
                  
                  Veja em detalhes o que a <strong>MOU</strong> oferece para você:
                </span>
              }
            />
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default Introduction;

const styles = {
  section: {
    pt: [6, null, null, 6, 8, 9],
    pb: [6, null, null, null, 7, 9, 11, null],
  },
  heading: {
    textAlign: ['left', null, null, 'left', 'left'],
    ml: [null, null, null, 'auto'],
    maxWidth: 'none !important',
    h2: {
      fontSize: [null, null, null, 10, 8, 30],
    },
    p: {
      fontSize: [null, null, null, 3],
      maxWidth: 'none'
    },
    strong: {
      fontWeight: 'bold',
      color: '#CC6666'
    },
  },
};
