/** @jsx jsx */
import { jsx, Box, Container, Grid } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';
import Accordion from 'components/accordion/accordion';
import Image from 'components/image';
import emoji from 'assets/images/icons/emoji-2.png';
import featureImage from 'assets/images/tailoring.jpg'

const data = [
  {
    title: 'Tabela de Medidas',
    contents: (
      <div>
        Customize seus projetos, de acordo com suas necessidades, com ajustes simples e rápidos em diferentes tabelas de medidas.
      </div>
    ),
  },
  {
    title: 'Gradação Automática',
    contents: (
      <div>
        Com um simples clique, veja sua modelagem ser graduada automaticamente, em todos os tamanhos desejados.
      </div>
    ),
  },
  {
    title: `Facilidade`,
    contents: (
      <div>
        Um ambiente web completo e fácil, que descomplica sua vida na hora de fazer o que você mais gosta, criar!
      </div>
    ),
  },
];

const PremiumFeature = () => {
  const image = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "messenger.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <section id="features" sx={styles.section}>
      <Container>
        <Grid sx={styles.grid}>
          <Box as="figure" sx={styles.illustration}>
            <img
              // src={image.illustration.childImageSharp.fluid}
              src={featureImage}
              alt="features"
            />
          </Box>
          <Box sx={styles.rightContent}>
            <SectionHeading
              // emoji={emoji}
              sx={styles.heading}
              title="Na MOU temos novidades exclusivas para você!"
              description="Faça seus projetos do zero, com toda a praticidade que a modelagem digital pode oferecer."
            />
            <Box sx={styles.accordionGroup}>
              <Accordion items={data} />
            </Box>
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default PremiumFeature;

const styles = {
  section: {
    pt: [6, null, null, 6, 8, 9],
    pb: [6, null, null, null, 7, 9, 11, null],
  },
  grid: {
    alignItems: 'center',
    gridTemplateColumns: [
      '1fr',
      null,
      null,
      null,
      '1fr 431px',
      '1fr 530px',
      '1fr 550px',
    ],
  },
  heading: {
    textAlign: ['left', null, null, 'center', 'left'],
    ml: [null, null, null, 'auto'],
    maxWidth: [null, null, null, 520, 660],
    h2: {
      fontSize: [null, null, null, 10, 8, 40],
      img: {
        maxWidth: [24, null, null, 30, 25, null, '100%'],
        top: ['4px', '8px', null, null, '4px', '8px'],
      },
    },
    p: {
      fontSize: [null, null, null, 2],
    },
  },
  illustration: {
    mb: [-6, null, null, -8, 0],
    position: 'relative',
    zIndex: -1,
    img: {
      opacity: 0.9,
      width: 550,
      height: 500,
      borderRadius:'15px 50px 30px 5px'
    }
  },
  accordionGroup: {
    m: [null, null, null, '0 auto', 'unset'],
    maxWidth: [null, null, null, 600, 'none'],
  },
};
