/** @jsx jsx */
import { jsx, Box, Container, Grid } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';
import Accordion from 'components/accordion/accordion';
import featureGif from 'assets/gifs/calculadora-gif.gif'

const data = [
  {
    title: 'Na medida certa',
    contents: (
      <div>
        Conexão automática entre a tabela de medidas e a modelagem.<br/>
        Todas as medidas, registradas em uma tabela, podem ser utilizadas através de um sistema de busca integrado à calculadora.      
      </div>
    ),
  },
  {
    title: 'Trabalho facilitado',
    contents: (
      <div>
        A Calculadora facilita o desenvolvimento de diagramas e moldes, com um rápido acesso à informação e cálculos dinâmicos para marcação de medidas no traçado.
      </div>
    ),
  },
  // {
  //   title: 'Calculadora nativa do sistema',
  //   contents: (
  //     <div>
  //       Na MOU você não precisa mais fazer os cálculos da modelagem com uma calculadora convencional, pois a plataforma oferece uma 
  //       calculadora na área de trabalho e permite buscar dos valores para calculo diretamente da tabela de medidas integrada!
  //     </div>
  //   )
  // }
  
];

const CalculatorFeature = () => {
  
  return (
    <section id="features" sx={styles.section}>
      <Container>
        <Grid sx={styles.grid}>
          <Box>
            <SectionHeading
              // emoji={emoji}
              sx={styles.heading}
              title="Calculadora"
              description="Utilize o poder computacional que a MOU oferece com a calculadora para modelar os projetos, economize tempo e reduza erros no processo."
            />
            <Box sx={styles.accordionGroup}>
              <Accordion items={data} />
            </Box>
          </Box>
          <Box as="figure" sx={styles.illustration}>
            <img
              // src={image.illustration.childImageSharp.fluid}
              src={featureGif}
              alt="features"
            />
            {/* <GifPlayer 
              gif={featureGif} 
              autoplay={true}
              still={thumbs}
              /> */}
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default CalculatorFeature;

const styles = {
  section: {
    pt: [null],
    pb: [6, null, null, null, 7, 9, 11, null],
  },
  grid: {
    alignItems: 'center',
    gridTemplateColumns: [
      '1fr',
      '1fr',
      '1fr',
      '1fr',
      '1fr 420px',
      '1fr 530px',
      '1fr 550px',
    ],
  },
  heading: {
    textAlign: ['left', null, null, 'left', 'left'],
    m:['0 auto','0 auto','0 auto','0 auto',null],
    ml: [null, null, null, 'auto'],
    width:['auto','auto','auto','auto',420,500],
    maxWidth: [null, null, null, 520, 660],
    h2: {
      fontSize: [null, null, null, 10, 8, 40],
      img: {
        maxWidth: [24, null, null, 30, 25, null, '100%'],
        top: ['4px', '8px', null, null, '4px', '8px'],
      },
    },
    p: {
      fontSize: [null, null, null, 2],
    },
  },
  illustration: {
    m:['0 auto','0 auto','0 auto','0 auto',null],
    mb: [6, null, null, 8, 0],
    // mr:[1,null, null,10,15],
    position: 'relative',
    zIndex: -1,
    img: {
      opacity: 0.9,
      width: [250,300,300,400,500,500,500],
      height: [200,250,250,350,450,450,450],
      borderRadius:'15px 15px 15px 15px',
      boxShadow:"gray 0px 4px 12px",
    }
  },
  accordionGroup: {
    width:['auto','auto','auto','auto',420,500],

    m:['0 auto','0 auto','0 auto','0 auto',null],
    maxWidth: [null, null, null, 600, 'none'],
  },
};
