/** @jsx jsx */
import { jsx, Container, Grid, Box, Button, Heading, Text } from 'theme-ui';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { IoIosCloseCircle } from 'react-icons/io';
import SectionHeading from 'components/section-heading';

const ContentTable = ({data}) => {
    
    return (
        <section sx={styles.section}>
        <SectionHeading
          sx={styles.heading}
          title="Todos os planos incluem:"
        />
        <Container sx={styles.contentTable}>
            <Box as='ul' sx={styles.list}>
                {data[0].features?.map((feat) => (
                    <li key={feat.id} className={!feat.isAvailable ? 'unavailable' : ''}>
                        {feat.isAvailable ? (
                            <span>
                                <IoMdCheckmarkCircle sx={{ color: 'primary'}} size ='23px' />
                            </span>
                        ) : (
                            <span>
                                <IoIosCloseCircle color="#CED7E1" size="23px" />
                            </span>
                        )}
                        <span>{feat.title}</span>
                    </li>
                ))}
            </Box>
            <Box as='ul' sx={styles.list}>
                {data[1].features?.map((feat) => (
                    <li key={feat.id} className={!feat.isAvailable ? 'unavailable' : ''}>
                        {feat.isAvailable ? (
                            <span>
                                <IoMdCheckmarkCircle sx={{ color: 'primary'}} size ='23px' />
                            </span>
                        ) : (
                            <span>
                                <IoIosCloseCircle color="#CED7E1" size="23px" />
                            </span>
                        )}
                        <span>{feat.title}</span>
                    </li>
                ))}
            </Box>
        </Container>
        </section>
    )
};

export default ContentTable;

const styles = {
    section: {
        pb: [6]
    },
    contentTable:{
        background: 'white',
        borderRadius: 10,
        position: 'relative',
        padding: '20px',
        paddingBottom: 0,
        flexDirection: ['column',"row"],
        display:"flex",
        alignItems: "center",
        boxShadow: '0px 10px 60px rgba(62, 95, 119, 0.08)',
        width: '70%',
    },
    heading: {
        mb: 4,
        h2: {
          fontSize: [6, null, null, 8],
        },
        p: {
          color: '#858B91',
          fontSize: 3,
          m: ['10px auto', null, null, '0 auto'],
        },
      },
    list:{
        // border: `1px solid #E0E2E4`,
        borderRadius: 10,
        listStyle: 'none',
        padding: 0,
        // mt: [17],
        mr:[null,null,5,5,5],
        flexDirection: 'column',
        pb: [7],
        display: 'flex',
        alignItems:'flex-start',
        // background: 'white',

        li: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
            lineHeight: 1.62,
            '+ li ': {
                mt: 30,
            },
            'span:first-of-type': {
                mr: '13px',
                mt: '5px',
            },
        },
        '.unavailable': {
        opacity: 0.5,
        },
    },
       

}