/** @jsx jsx */
import { jsx, Box, Container, Button, IconButton, Image,   } from 'theme-ui';
import { rgba } from 'polished';
import SectionHeading from 'components/section-heading';
import { Fragment, useState } from 'react';
import ContactForm from '../components/contactForm';
import {Link} from '../components/link';
import InstaLogo from '../assets/images/icons/insta_logo_color.png'

const Subscribe = ({contactFormHandler, show}) => {
  let headingContent= <div>
                        <div>Preencha o formulário e nossa equipe entrará em contato o mais rápido possível, ou nos visite em nossa rede social! </div>
                        <div sx={styles.instaLogoLink}>
                          <Link path={"https://www.instagram.com/mou.digital/"}>
                            {/* <IconButton> */}
                              <Image src={InstaLogo} alt="Insta icon" />
                            {/* </IconButton> */}
                            @mou.digital
                          </Link>
                        </div>
                      </div>;
  return (
    <section id="contact" sx={styles.sectionContainter}>
      <Container sx={styles.section}>
        <Box as="section">
          <SectionHeading
            sx={styles.heading}
            title="Fale conosco!"
            // description="Preencha o formulário e nossa equipe entrará em contato o mais rápido possível, ou nos visite em nossas redes sociais!"
            description= {headingContent}
          />
        </Box>
        
        <Box>
          {show ? 
            <ContactForm sx={styles.contactForm}/>
            : 
            <div sx={styles.lkBtnContainer}>
              <Button sx={styles.linkButton} disabled={show} onClick={() => contactFormHandler(show)}>
                Entre em contato
              </Button>
            </div>
          }
        </Box>
        {/* <Box>
          <IconButton>
            <Image src={InstaLogo} alt="Insta icon" />
          </IconButton>
        </Box> */}
      </Container>  
    </section>
  );
};
export default Subscribe;

const styles = {
  sectionContainter: {
    backgroundColor: '#353448',
  },
  section: {
    display: 'grid',
    gridTemplateColumns: [null, null, null, null, '1fr 1fr', '0.95fr 1.05fr'],
    itemAligment:'flex-end',
    pt: [9, null, null, null, null, null, null],
    pb: [9, null, null, null, null, null, null],
  },
  heading: {
    mb: [7, null, null, 8, 9, 10],
    h2: {
      color: 'white',
      fontSize: [6, null, null, 8],
      textAlign: 'left',
    },
    p: {
      color: '#858B91',
      fontSize: [2, null, null, 3],
      textAlign: 'left',
    },
  },
  subscriptionForm: {
    flexDirection: ['column', null, null, 'row'],
    input: {
      backgroundColor: rgba('white', 0.08),
      border: '0 none',
      color: rgba('white', 0.8),
      fontFamily: 'body',
      px: [5],
      minHeight: [50, null, null, null, 60],
      boxShadow: '0px 16px 40px rgba(72, 59, 26, 0.08)',
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      '::placeholder': {
        color: rgba('white', 0.8),
        opacity: 1 /* Firefox */,
      },
    },
    button: {
      fontSize: [1, null, null, 2],
      minHeight: [50, null, null, null, 60],
      ml: [0, null, null, 3],
      mt: [4, null, null, 0],
    },
  },
  linkButton: {
      // display: 'flex',
      // alignItems:'center',
      fontSize: [1, null, null, 2],
      minHeight: [50, null, null, null, 60],
      margin: '50px auto'
  },
  lkBtnContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  contactForm:{
    width: '100%',
    backgroundColor:'#353448',
    // marginBottom:300,
    // flexDirection: ['column', null, null, 'row'],
    input: {
      width: '100%',
      backgroundColor: rgba('white',1),
      border: '0 none',
      fontFamily: 'body',
      px: [5],
      minHeight: [50, null, null, null, 60],
      boxShadow: '0px 16px 40px rgba(72, 59, 26, 0.08)',
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      '::placeholder': {
        color: rgba('black', 0.8),
        opacity: 1 /* Firefox */,
      },
    },
    button: {
      fontSize: [1, null, null, 2],
      minHeight: [50, null, null, null, 60],
      ml: [0, null, null, 3],
      mt: [4, null, null, 0],
    },
  },
  instaLogoLink: {
    a: {
      textDecoration:"none",
      color: "#858B91",
      // ml:10
    },
    img:{
      width:25,
      position: 'relative',
      top: 8.5
    }
  }
};
