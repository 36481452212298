/** @jsx jsx */
import { useState } from 'react';
import { keyframes } from '@emotion/react';
import { jsx, Box, Container, Flex, Text } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import PriceTable from 'components/cards/price-table';
import ContentTable from '../components/cards/contentTable';
import Switch from 'components/switch';

const data = [
  {
    id: 1,
    title: 'Plano Mensal',
    subtitle: 'Renove a licença mensalmente',
    amount: {
      monthly: 195,
      annual: 195,
    },
    isRecommended: false,
    buttonText: 'Plano Mensal',
    features: [
      {
        id: 1,
        isAvailable: true,
        title: 'Ultimate access to all course, exercises and assessments',
      },
      {
        id: 2,
        isAvailable: true,
        title: `Free access for all kind of exercise corrections with downloads.`,
      },
      {
        id: 3,
        isAvailable: true,
        title: `Total assessment corrections with free download access system`,
      },
      {
        id: 4,
        isAvailable: false,
        title: `Unlimited download of courses on the mobile app contents`,
      },
      {
        id: 5,
        isAvailable: false,
        title: `Download and print courses and exercises in PDF`,
      },
    ],
  },
  {
    id: 2,
    title: 'Plano Semestral',
    subtitle: 'Renove a licença semestralmente',
    amount: {
      monthly: 175,
      annual: 175,
    },
    isRecommended: false,
    buttonText: 'Plano Semestral',
    features: [
      {
        id: 1,
        isAvailable: true,
        title: 'Ultimate access to all course, exercises and assessments',
      },
      {
        id: 2,
        isAvailable: true,
        title: `Free access for all kind of exercise corrections with downloads.`,
      },
      {
        id: 3,
        isAvailable: true,
        title: `Total assessment corrections with free download access system`,
      },
      {
        id: 4,
        isAvailable: true,
        title: `Unlimited download of courses on the mobile app contents`,
      },
      {
        id: 5,
        isAvailable: true,
        title: `Download and print courses and exercises in PDF`,
      },
    ],
  },
  {
    id: 3,
    title: 'Plano Anual',
    subtitle: 'Renove a licença anualmente',
    amount: {
      monthly: 155,
      annual: 155,
    },
    isRecommended: false,
    buttonText: 'Plano Anual',
    features: [
      {
        id: 1,
        isAvailable: true,
        title: 'Ultimate access to all course, exercises and assessments',
      },
      {
        id: 2,
        isAvailable: true,
        title: `Free access for all kind of exercise corrections with downloads.`,
      },
      {
        id: 3,
        isAvailable: true,
        title: `Total assessment corrections with free download access system`,
      },
      {
        id: 4,
        isAvailable: true,
        title: `Unlimited download of courses on the mobile app contents`,
      },
      {
        id: 5,
        isAvailable: true,
        title: `Download and print courses and exercises in PDF`,
      },
    ],
  }
];

const contentData = [
  { //first column
    features:[
      {
        id: 1,
        isAvailable: true,
        title: 'Licença de usuário',
      },
      {
        id: 2,
        isAvailable: true,
        title: <span>Acesso ao ambiente de Modelagem<br />(bases e interpretações)</span>,
      },
      {
        id: 3,
        isAvailable: true,
        title: 'Acesso a área de gerenciamento de tabelas de medidas',
      },
    ]
  },
  { //second Column
    features:[
      {
        id: 4,
        isAvailable: true,
        title: 'Gradação Instantânea',
      },
      {
        id: 5,
        isAvailable: true,
        title: `Impressão de modelagens e tabelas de medidas`,
      },
      ,
      {
        id: 6,
        isAvailable: false,
        title: `Encaixe (EM BREVE!)`,
      },
    ]
  },
];

const Pricing = (props) => {
  const [isMonthly, setIsMonthly] = useState(true);

  const handlePlan = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <Box as="section" id="pricing" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          title="Conheça nossos planos "
          // description="3 formas de pagamento"
        />
        {/* <Flex sx={styles.priceSwitcher}>
          <Text as="span">Monthly Plan</Text>
          <Switch isMonthly={isMonthly} handlePlan={handlePlan} />
          <Text as="span">Annual Plan</Text>
        </Flex> */}
        <Box sx={styles.priceWrapper}>
          {data?.map((price, index) => (
            <PriceTable
              price={price}
              isAnnual={!isMonthly}
              key={`${isMonthly}-${index}`}
              parentHandler={props}
            />
          ))}
          
        </Box>
        <ContentTable data={contentData}/>
      </Container>
    </Box>
  );
};

export default Pricing;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeIn2 = keyframes`
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const styles = {
  section: {
    backgroundColor: 'transparent',
  },
  heading: {
    mb: 4,
    h2: {
      fontSize: [6, null, null, 8],
    },
    p: {
      color: '#858B91',
      fontSize: 3,
      m: ['10px auto', null, null, '0 auto'],
    },
  },
  priceSwitcher: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
    p: 2,
    span: {
      color: 'text',
      fontWeight: 500,
      outline: 0,
      p: 0,
      minHeight: 'auto',
    },
  },
  priceWrapper: {
    display: ['block', null, null, 'flex'],
    alignItems: 'center',
    justifyContent: 'center',
    '.priceCard': {
      '.priceHeader': {
        animation: `${fadeIn} 0.8s linear`,
      },
      'ul > li': {
        animation: `${fadeIn2} 0.7s linear`,
      },
      '.priceAmount': {
        animation: `${fadeIn} 0.9s linear`,
      },
      '.priceButton': {
        animation: `${fadeIn2} 0.7s linear`,
      },
    },
  },
};
