/** @jsx jsx */
import { jsx, Box, Container, Grid } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';
import Accordion from 'components/accordion/accordion';
import featureGif from 'assets/gifs/gradação-gif.gif'

const data = [
  {
    title: 'Tamanhos disponíveis',
    contents: (
      <div>
        Todos os tamanhos inseridos na tabela de medidas estão disponíveis na gradação, ative os tamanhos que desejar na área de grade da modelagem.
      </div>
    ),
  },
  {
    title: 'Reduza erros',
    contents: (
      <div>
        Utilize a tabela de medidas e faça as marcações dos pontos no traçado utilizando a calculadora do sistema, 
        assim seu trabalho é criado com cálculos dinâmicos e fica pronto para todos os demais tamanhos.<br/>
        O conhecimento aplicado para desenvolver um tamanho base é replicado automaticamente na <strong>MOU</strong> para os demais 
        tamanhos da tabela de medidas a partir da repetição automática da ordem de execução da modelagem.
      </div>
    ),
  },
  
];

const GraduationFeature = () => {
  
  return (
    <section id="features" sx={styles.section}>
      <Container>
        <Grid sx={styles.grid}>
          <Box>
            <SectionHeading
              // emoji={emoji}
              sx={styles.heading}
              title="Gradação Instantânea"
              description={
                <span>
                  <strong><i>Sabia que o processo de gradação pode ocupar até 75% do tempo total de modelagem de uma peça?</i></strong><br />
                  Na MOU, uma modelagem com uma tabela de medidas preenchida e com marcações de medidas dinâmicas no traçado pode gradar para 
                  qualquer tamanho da tabela, com apenas um clique!
                </span>
              }
            />
            <Box sx={styles.accordionGroup}>
              <Accordion items={data} />
            </Box>
          </Box>
          <Box as="figure" sx={styles.illustration}>
            <img
              // src={image.illustration.childImageSharp.fluid}
              src={featureGif}
              alt="features"
            />
            {/* <GifPlayer
              gif={featureGif}
              autoplay={true}
              still={thumbs}
            /> */}
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default GraduationFeature;

const styles = {
  section: {
    pt: [null],
    pb: [6, null, null, null, 7, 9, 11, null],
  },
  grid: {
    alignItems: 'center',
    gridTemplateColumns: [
      '1fr',
      '1fr',
      '1fr',
      '1fr',
      '1fr 420px',
      '1fr 530px',
      '1fr 550px',
    ],
  },
  heading: {
    textAlign: ['left', null, null, 'left', 'left'],
    m:['0 auto','0 auto','0 auto','0 auto',null],
    ml: [null, null, null, 'auto'],
    width:['auto','auto','auto','auto',420,500],
    maxWidth: [null, null, null, 520, 660],
    h2: {
      fontSize: [null, null, null, 10, 8, 40],
      img: {
        maxWidth: [24, null, null, 30, 25, null, '100%'],
        top: ['4px', '8px', null, null, '4px', '8px'],
      },
    },
    p: {
      fontSize: [null, null, null, 2],
    },
  },
  illustration: {
    m:['0 auto','0 auto','0 auto','0 auto',null],
    mb: [6, null, null, 8, 0],
    // mr:[1,null, null,10,15],
    position: 'relative',
    zIndex: -1,
    img: {
      opacity: 0.9,
      width: [250,300,300,400,500,500,500],
      height: [200,250,250,350,450,450,450],
      borderRadius:'15px 15px 15px 15px',
      boxShadow:"gray 0px 4px 12px",
    }
  },
  accordionGroup: {
    width:['auto','auto','auto','auto',420,500],

    m:['0 auto','0 auto','0 auto','0 auto',null],
    maxWidth: [null, null, null, 600, 'none'],
  },
};
