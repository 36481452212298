/** @jsx jsx */
import { Fragment, useState } from 'react';
import { jsx, Flex, Input, Button, Label, Box, Heading, Text, Select, Textarea , Grid } from 'theme-ui';
import emailValidator from 'email-validator';
import { addSubscription } from '../shared/firebase-functions';
import { sendContactForm } from '../shared/firebase-functions';
import Loader from './loader/loader';

const ContactForm = ({ buttonLabel, ...props }) => {
  const
    [ name, setName ] = useState(''),
    [ email, setEmail ] = useState(''),
    [ subject, setSubject] = useState(''),
    [ text, setText] = useState(''),
    [ loading, setLoading ] = useState(false),
    [ step, setStep ] = useState('form'),
    [ errorMessage, setErrorMessage ] = useState(null),
    canSend = 
      name !== '' 
      && emailValidator.validate(email) 
      && subject !== ''
      && text !== ''
      && !loading,
    onSubmit = (e) => {
      e.preventDefault();
      if(!canSend){
        return;
      }
      setLoading(true);
      setErrorMessage(null);
      const
        payload = {
          displayName: name,
          email: email,
          subject: subject,
          text: text,
          // accountType: props.accountType
        }
      sendContactForm(payload)
        .then( ({ data: {success, reason} })  => {
          let message;
          setLoading(false);
          if(success === false){
            switch(reason){
              case 'auth/email-already-exists':
                message = 'Este e-mail já está cadastrado na MOU.';
                break;
              default:
                message = 'Preencha o formulário corretamente.'
            }
            setErrorMessage(message);
          } else {
            setStep('success');
            if(typeof window !== "undefined" && window.gtag){
              window.gtag("event","confirmationContactFormEvent")
            }
          }
        })
        .catch( error => {
          setStep('error');
        });
    };

    let sendContactFormHandler = () => {
      if(typeof window !== "undefined" && window.gtag){
        window.gtag("event","sendContactFormEvent");
      }
    };

    var 
      welcomeText = "Nos envie por aqui sua mensagem, ficaremos felizes em te ajudar a tirar todas as suas dúvidas!"

  return (
    <Fragment>
    {step === 'form' &&
      <Fragment>
          <Grid gap={1} columns={1}>
            <Flex as="form" sx={styles.form} {...props} onSubmit={onSubmit}>
                <Box>       
                    <Label htmlFor={`nome`} variant="styles.srOnly">
                        Nome
                    </Label>
                    <Input
                      type="text"
                      id={`nome`}
                      placeholder="Informe o seu nome"
                      value={name}
                      onChange={ e => setName(e.target.value)}
                      disabled={loading}
                    />
                </Box>
                <Box>
                    <Label htmlFor={`email`} variant="styles.srOnly">
                      E-mail
                    </Label>
                    <Input
                      type="email"
                      id={`email`}
                      placeholder="Informe o seu e-mail"
                      value={email}
                      onChange={ e => setEmail(e.target.value)}
                      disabled={loading}
                    />
                </Box>
                <Box>
                  <Label htmlFor={'text'} variant={'styles.srOnly'}>Assunto</Label>
                  <Input 
                    type={'text'}
                    id={'_subject'}
                    placeholder={'Assunto'}
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    disabled={loading}
                  />
                </Box>
                <Box>
                    <Label htmlFor={"comment"} variant="styles.srOnly">Nos conte por aqui</Label>
                    <Textarea 
                      name="comment" 
                      id="comment" 
                      rows={5} 
                      mb={3} 
                      onChange={(e => setText(e.target.value))}
                      placeholder={'Mensagem'}
                    />
                </Box>  
                <Flex>
                    <Box>   
                        <Button
                            variant={canSend ? 'primary' : 'disabled'}
                            //variant={'primary'}
                            onClick={() => sendContactFormHandler()}
                        >
                            {loading ? 'Enviando...' : 'Enviar'}
                        </Button>
                    </Box>
                    <Box>
                    { loading && <Loader /> }
                    { errorMessage && 
                        <Text 
                        as={'p'} 
                        sx={styles.errorMessage}
                        >
                        {errorMessage}
                        </Text>
                    }
                    </Box>
                </Flex>
              </Flex>
            </Grid>
      </Fragment>
    }
    {step === 'success' &&
      <Box sx={styles.heading}>
        <Heading as="h1">
          Seu formulário foi enviado com sucesso!
        </Heading>
        <Text as="p">
          Em breve, entraremos em contato com você através do e-mail informado.<br />
          {/* <Button as={'a'} href={'https://app.mou.digital'}>Acesse sua conta</Button> */}
        </Text>
      </Box>
    }
    {step === 'error' &&
      <Box sx={styles.heading}>
        <Heading as="h1">
          Ops... 
        </Heading>
        <Text as="p">
          Não foi possível enviar sua solicitação.<br/>
          Tente novamente mais tarde!<br />
          <Button onClick={() => setStep('form')}>Voltar</Button>
        </Text>
      </Box>
    }
    </Fragment>
  );
};

export default ContactForm;

const styles = {
  link: {
    textDecoration: 'none'
  },
  form: {
    margin: '0px',
    flexDirection: 'column',
    alignItems: 'stretch',
    input: {
      p: ['0 20px', null, null, null, '0 25px'],
      minHeight: [60],
      height: 'auto',
      width: '100% !important',
      border: '1px solid #eee',
      color:'black',
      marginBottom: '15px'
    },
    textarea: {
      p: ['10px 20px', null, null, null, '10px 25px'],
      minHeight: [60],
      height: 'auto',
      width: '100% !important',
      border: '1px solid #eee',
      color:'black',
      backgroundColor: '#FDFDFD',
      fontFamily: 'body',
    },
    button: {
      marginLeft: '0px !important'
    }
  },
  errorMessage: {
    fontSize: '16px !important',
    lineHeight: '1 !important',
    margin: '0 !important',
    marginLeft: '10px !important',
    marginTop: '17px !important',
    padding: '0 !important'
  },
  select:{
    mb:100,
    minWidth:250,
    width: "auto"
  },
  heading: {
    h1: {
      color:'white'
    },
    p:{
      color:'#858B91'
    }
  }
};
