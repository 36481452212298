/** @jsx jsx */
import { jsx, Box, Container, Grid } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';
import Accordion from 'components/accordion/accordion';
import featureImage from 'assets/images/impressao.jpg'

const data = [
  {
    title: 'Área dedicada',
    contents: (
      <div>
        Imprima suas modelagens na área dedicada, em diferentes configurações de tamanho de papel, selecionando apenas o que interessa para você.
      </div>
    ),
  },
  {
    title: 'Imprima em casa!',
    contents: (
      <div>
        Na MOU, as modelagens podem ser impressas em uma impressora doméstica sem a necessidade de equipamentos especializados.
      </div>
    ),
  },
  {
    title: `Exporte seu arquivo`,
    contents: (
      <div>
        As modelagens podem ser exportadas nos formatos PDF e PLT, podendo ser impressos nos mais variados modelos de plotters disponíveis.
      </div>
    ),
  },
];

const PrintFeature = () => {
  
  return (
    <section id="features" sx={styles.section}>
      <Container>
        <Grid sx={styles.grid}>
          <Box>
            <SectionHeading
              // emoji={emoji}
              sx={styles.heading}
              title="Impressão ao seu alcance"
              description="Sem acesso a equipamentos de impressão especializados ou de alto custo? Sem problemas, a MOU resolve para você!"
            />
            <Box sx={styles.accordionGroup}>
              <Accordion items={data} />
            </Box>
          </Box>
          <Box as="figure" sx={styles.illustration}>
              <img
              // src={image.illustration.childImageSharp.fluid}
              src={featureImage}
              alt="features"
              />
              {/* <GifPlayer 
                gif={featureGif} 
                autoplay={true}
                still={thumbs}
              /> */}
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default PrintFeature;

const styles = {
  section: {
    pt: [null],
    pb: [6, null, null, null, 7, 9, 11, null],
  },
  grid: {
    alignItems: 'center',
    gridTemplateColumns: [
      '1fr',
      '1fr',
      '1fr',
      '1fr',
      '1fr 420px',
      '1fr 530px',
      '1fr 550px',
    ],
  },
  heading: {
    position:[null,null,'relative','relative'],
    left:[null,null,null,null,'520px','550px'],
    m:['0 auto','0 auto','0 auto','0 auto',null],
    textAlign: ['left', 'left', 'left', 'left', 'left'],
    // m: [null, null, null,60,60, 'auto'],
    mb:[null,null,null,null,5],
    width:['auto','auto','auto','auto',420,500],
    maxWidth: [null, null, null, 520, 660],
    h2: {
      fontSize: [null, null, null, 10, 8, 40],
      img: {
        maxWidth: [24, null, null, 30, 25, null, '100%'],
        top: ['4px', '8px', null, null, '4px', '8px'],
      },
    },
    p: {
      fontSize: [null, null, null, 2],
    },
  },
  illustration: {
    m:['0 auto','0 auto','0 auto','0 auto',null],
    mb: [6, null, null, 8, 0],
    ml:[null,null, null,'0 auto',null],
    position: ['relative','relative','relative','relative','absolute'],
    zIndex: -1,
    img: {
      opacity: 0.9,
      width: [250,300,300,400,500,500,500],
      height: [200,250,250,350,450,450,450],
      borderRadius:'15px 15px 15px 15px',
      boxShadow:"gray 0px 4px 12px",
    }
  },
  accordionGroup: {
    width:['auto','auto','auto','auto',420,500],
    position:[null,null,null,'relative'],
    left:[null,null,null,null,'520px','550px'],
    m:['0 auto','0 auto','0 auto','0 auto',null],
    maxWidth: [null, null, null, 600, 'none'],
  },
};
