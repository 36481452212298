/** @jsx jsx */
import { jsx, Box, Container, Grid } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';
import Accordion from 'components/accordion/accordion';
import featureGif from 'assets/gifs/ordem-gif.gif';
import thumbs from "assets/images/thumbnails/thumbnail_example.png"
import loadable from '@loadable/component';

const GifPlayer = loadable(() => require('react-gif-player'));


const data = [
  {
    title: 'Histórico',
    contents: (
      <div>
        Com um simples comando é possível retroceder no tempo e verificar como foi feita a modelagem.<br/>
        A barra de navegação da ordem de execução facilita a exploração das informações registradas.
      </div>
    ),
  },
  {
    title: 'Compartilhamento',
    contents: (
      <div>
        Quando uma modelagem é compartilhada com outro usuário, a ordem de execução agiliza a compreensão do trabalho para quem está visitando o projeto pela primeira vez, 
        pois permite conhecer toda a construção, medidas utilizadas e elementos inseridos, desde o início.
      </div>
    ),
  },
  
];

const InstructionFeature = () => {
  
  return (
    <section id="features" sx={styles.section}>
      <Container>
        <Grid sx={styles.grid}>
          <Box>
            <SectionHeading
              // emoji={emoji}
              sx={styles.heading}
              title="Ordem de Execução"
              description="Na MOU, você tem o acesso ao passo-a-passo de sua modelagem, desde a concepção até a etapa final. 
              O painel de Ordem de Execução foi pensado para registrar instruções de uma forma simples e organizada, além da possibilidade de navegação entre os registros, voltando no tempo, literalmente!"
            />
            <Box sx={styles.accordionGroup}>
              <Accordion items={data} />
            </Box>
          </Box>
          <Box as="figure" sx={styles.illustration}>
            <img
            // src={image.illustration.childImageSharp.fluid}
            src={featureGif}
            alt="features"
            />
            {/* <GifPlayer 
              gif={featureGif} 
              autoplay={true}
              still={thumbs}
            /> */}
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default InstructionFeature;

const styles = {
  section: {
    pt: [0],
    pb: [6, null, null, null, 7, 9, 11, null],
  },
  grid: {
    alignItems: 'center',
    gridTemplateColumns: [
      '1fr',
      '1fr',
      '1fr',
      '1fr',
      '1fr 420px',
      '1fr 530px',
      '1fr 550px',
    ],
  },
  heading: {
    position:[null,null,'relative','relative'],
    left:[null,null,null,null,'520px','550px'],
    m:['0 auto','0 auto','0 auto','0 auto',null],
    textAlign: ['left', 'left', 'left', 'left', 'left'],
    // m: [null, null, null,60,60, 'auto'],
    mb:[null,null,null,null,5],
    width:['auto','auto','auto','auto',420,500],
    maxWidth: [null, null, null, 520, 660],
    h2: {
      fontSize: [null, null, null, 10, 8, 40],
      img: {
        maxWidth: [24, null, null, 30, 25, null, '100%'],
        top: ['4px', '8px', null, null, '4px', '8px'],
      },
    },
    p: {
      fontSize: [null, null, null, 2],
    },
  },
  illustration: {
    m:['0 auto','0 auto','0 auto','0 auto',null],
    mb: [6, null, null, 8, 0],
    ml:[null,null, null,'0 auto',null],
    position: ['relative','relative','relative','relative','absolute'],
    zIndex: -1,
    img: {
      opacity: 0.9,
      width: [250,300,300,400,500,500,500],
      height: [200,250,250,350,450,450,450],
      borderRadius:'15px 15px 15px 15px',
      boxShadow:"gray 0px 4px 12px",
    }
  },
  accordionGroup: {
    width:['auto','auto','auto','auto',420,500],
    position:[null,null,null,'relative'],
    left:[null,null,null,null,'520px','550px'],
    m:['0 auto','0 auto','0 auto','0 auto',null],
    maxWidth: [null, null, null, 600, 'none'],
  },
};
